import { formatCurrency } from "../../../utils/formatCurrency";
import formatDate from "../../../utils/formatDate";
import { formatPercentage } from "../../../utils/formatPercentage";
import returnStatusMeasure from "../../../utils/returnStatusMeasure";
import returnStatusMeasurementPartials from "../../../utils/returnStatusMeasurementPartials";
import { TextCropToltip } from "../../TextCropToltip";

export const partialColumns = [
  { title: "Percentual acumulado", dataIndex: "percentage", render: (percentage: any) => <>{percentage ? formatPercentage(percentage) : "-"}</> },
  { title: "Percentual solicitado", dataIndex: "difference", render: (percentage: any) => <>{percentage ? formatPercentage(percentage) : "-"}</> },
  { title: "Valor de avanço", dataIndex: "cost_percentage_value", render: (value: any) => <>{value ? formatCurrency(value) : "-"}</> },
  { title: "Status", dataIndex: "status_id", render: (status: any) => <>{returnStatusMeasurementPartials(status)}</> },
  { title: "Data", dataIndex: "execution_date", render: (date: any) => <>{formatDate(date)}</> },
  { title: "Data de Aprovação", dataIndex: "approved_date", render: (date: any) => <>{date ? formatDate(date) : "-"}</> },
  { title: "Data de Reprovação", dataIndex: "reproved_date", render: (date: any) => <>{date ? formatDate(date) : "-"}</> },
  { title: "Justificativa", dataIndex: "justification" },

  { title: "", dataIndex: "actions" },
];

export const measurementsColumns = [
  { title: "Descrição", dataIndex: "description" },
  { title: "Avanço", dataIndex: "difference", render: (difference: any) => <>{formatPercentage(difference)}</> },
  { title: "Valor do avanço", dataIndex: "cost_percentage_value", render: (difference: any) => <>{formatCurrency(difference)}</> },
  { title: "Status", dataIndex: "status_id", render: (status: any) => <>{returnStatusMeasure(status)}</> },
  { title: "Data de execução", dataIndex: "execution_date", render: (date: any) => <>{date ? formatDate(date) : "-"}</> },
  { title: "Razão da recusa", dataIndex: "reason_for_refusal", render: (reason: any) => <TextCropToltip text={reason} max_quantity={15} /> },

  { title: "", dataIndex: "actions" },
];
