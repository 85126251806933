import { Popconfirm, Tooltip } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { IoMdDownload } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useAproveRefuseFile } from "../../hooks/files/useAproveRefuseFile";
import { useDeleteFile } from "../../hooks/files/useDeleteFile";
import { useDownloadFile } from "../../hooks/files/useDownloadFile";
import "./fileList.scss";
import RefuseModal from "./modalRefuse/modal";
import RefuseAfterApproveModal from "./modalRefuseAfterApprove/modal";
import PopConfirmIcon from "../PopConfirmIcon/PopConfirmIcon";

export function MeasureFileListActions({
  file,
  refreshList,
  setTableIsLoading,
  roles,
  hideActions,
  disableApprove,
  disableRefuse,
  alwaysShowRefuseAfterApprove,
}: {
  file: any;
  refreshList: VoidFunction;
  setTableIsLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  roles: string[];
  hideActions: boolean;
  disableApprove?: boolean;
  disableRefuse?: boolean;
  alwaysShowRefuseAfterApprove?: boolean;
}) {
  const { handleDelete, isFetching: isFetchingDelete } = useDeleteFile();
  const { handleAprove, isFetching: isFetchingAproveRefuse } = useAproveRefuseFile();
  const { handleDownload, isFetching: isFetchingDownload } = useDownloadFile();
  const [refuseModalOpen, setRefuseModalOpen] = useState(false);
  const [refuseAfterApproveModalOpen, setrefuseAfterApproveModalOpen] = useState(false);

  const userCanAprove = () => {
    const { uploadData } = file;
    if (roles.length === 0) return false;
    else if (["Administrador", "Owner"].some((item) => roles[0].includes(item))) return true;
    else if (roles[0].includes("Subowner")) {
      let currentStep;

      if (uploadData?.control_approved_by) return false;
      else if (uploadData?.quality_approved_by) currentStep = "control";
      else currentStep = "quality";

      return roles[0].includes("Subowner_" + currentStep);
    } else return false;
  };
  const canDelete = () => {
    if (["Administrador", "Owner"].some((item) => roles[0].includes(item))) return true;
    else if (file.user_id === localStorage.getItem("user_id")) return true;
    return false;
  };

  useEffect(() => {
    if (isFetchingDelete || isFetchingAproveRefuse || isFetchingDownload) {
      setTableIsLoading(true);
    } else if (!isFetchingDelete && !isFetchingAproveRefuse && !isFetchingDownload) {
      setTableIsLoading(false);
    }
  }, [isFetchingDelete, isFetchingAproveRefuse, isFetchingDownload, setTableIsLoading]);

  const aproveIsDisabled = () => !!file.uploadData.control_approved_by || !userCanAprove() || !!disableApprove;

  const refuseIsDisabled = () => {
    if (!!disableRefuse) return true;

    const { uploadData } = file;

    if (!userCanAprove()) return true;

    if (!uploadData.control_approved_by && !!uploadData.control_refused_by) {
      return true;
    } else if (!uploadData.quality_approved_by && !!uploadData.quality_refused_by) {
      return true;
    } else return !!uploadData.control_approved_by;
  };

  const enableRefuseAfterApproveIcon = () => {
    const { uploadData } = file;
    const userCanRefuse = (step: string) =>
      ["Administrador", "Owner"].some((item) => roles[0].includes(item)) || roles[0].includes("Subowner_" + step);

    if (!!uploadData.control_approved_by && !!uploadData.control_refused_by) {
      return true;
    } else if (!uploadData.control_approved_by && !uploadData.control_refused_by && !!uploadData.quality_approved_by && userCanRefuse("quality")) {
      return true;
    } else {
      return false;
    }
  };

  return roles.length > 0 ? (
    <div className="file-table-list-actions">
      <>
        <PopConfirmIcon
          title="Aprovar"
          description="Tem certeza que deseja aprovar essa evidência?"
          disabled={aproveIsDisabled()}
          onConfirm={() => !aproveIsDisabled() && !isFetchingDelete && handleAprove({ id: file.id, refreshList })}
          icon={<FaCheck size={18} className={aproveIsDisabled() ? "file-table-list-actions-disabled" : ""} />}
        />
        <div>
          <RefuseModal id={file.id} refreshList={refreshList} handleClose={() => setRefuseModalOpen(false)} isModalOpen={refuseModalOpen} />
          <PopConfirmIcon
            title="Reprovar"
            description="Tem certeza que deseja reprovar essa evidência?"
            onConfirm={() => !refuseIsDisabled() && !isFetchingDelete && setRefuseModalOpen(true)}
            disabled={refuseIsDisabled()}
            icon={<IoClose size={20} className={refuseIsDisabled() ? "file-table-list-actions-disabled" : ""} />}
          />
        </div>
      </>

      <Tooltip title="Download">
        <IoMdDownload size={20} onClick={() => !isFetchingDelete && handleDownload(file.id)} />
      </Tooltip>
      {canDelete() ? (
        <PopConfirmIcon
          title="Deletar"
          description="Tem certeza que deseja deletar essa evidência?"
          onConfirm={(setConfirmIsOpen) =>
            !isFetchingDelete &&
            handleDelete({
              id: file.id,
              refreshList,
              isOpen: () => {
                setConfirmIsOpen(false);
              },
            })
          }
          disabled={isFetchingAproveRefuse}
          icon={<FaTrashAlt size={18} color="red" />}
        />
      ) : null}

      {enableRefuseAfterApproveIcon() || alwaysShowRefuseAfterApprove ? (
        <div>
          <RefuseAfterApproveModal
            id={file.id}
            refreshList={refreshList}
            handleClose={() => setrefuseAfterApproveModalOpen(false)}
            isModalOpen={refuseAfterApproveModalOpen}
          />
          <PopConfirmIcon
            title={enableRefuseAfterApproveIcon() ? "Reprovar última etapa aprovada" : "Evidência está em outra etapa e não pode ser reprovada nessa"}
            description="Tem certeza que deseja reprovar essa evidência na etapa que já foi aprovada?"
            onConfirm={() => !isFetchingDelete && setrefuseAfterApproveModalOpen(true)}
            disabled={!enableRefuseAfterApproveIcon()}
            icon={<RiArrowGoBackFill size={18} className={!enableRefuseAfterApproveIcon() ? "file-table-list-actions-disabled" : ""} />}
          />
        </div>
      ) : null}
    </div>
  ) : (
    <></>
  );
}
