import { TableColumnsType } from "antd";
import returnStatusEvidence from "../../../utils/returnStatusEvidence";
import { formatCurrency } from "../../../utils/formatCurrency";
import { TextCropToltip } from "../../../components/TextCropToltip";
import { formatPercentage } from "../../../utils/formatPercentage";
import returnStatusActivity from "../../../utils/returnStatusActivity";

export const columns: TableColumnsType<any> = [
  {
    title: "Projeto",
    dataIndex: "project_name",
    key: "project_name",
  },

  {
    title: "CWA",
    dataIndex: "cwa",
    key: "cwa",
  },
  { title: "WP", dataIndex: "wp_name", key: "wp_name" },
  { title: "Código", dataIndex: "code", key: "code" },
  { title: "Etapa", dataIndex: "etapa", key: "etapa" },
  { title: "Step de medição", dataIndex: "description", key: "description" },

  {
    title: "Descrição da atividade",
    dataIndex: "joined_wp_description",
    key: "joined_wp_description",
    render: (description: any) => <TextCropToltip text={description} max_quantity={35} />,
  },
  {
    title: "Validação",
    dataIndex: "status_evidence",
    render: (status) => returnStatusEvidence(status),
    key: "status_evidence",
  },
  { title: "Peso", dataIndex: "percentual", key: "percentual", render: (name) => <div>{name ? formatPercentage(name, 2) : "-"}</div> },
  {
    title: "Valor",
    dataIndex: "cost",
    render: (name) => <div>{name ? formatCurrency(name, 2) : "-"}</div>,
    key: "cost",
  },
  { title: "Status", dataIndex: "status_activity", key: "status", render: (status) => returnStatusActivity(status) },

  { title: "Justificativa", dataIndex: "justification", key: "justification" },
  { title: "", dataIndex: "actions", key: "actions" },
];
