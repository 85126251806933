import { Table, Tabs } from "antd";
import "../measurePage.scss";

import { columns } from "../utils/columns";
import { activityTabs } from "../utils/tabs";

function ActivityTabs({
  activeKey,
  onChange,
  dataSource,
  loading,
  current,
  setCurrent,
  total,
}: {
  activeKey: string | undefined;
  onChange: ((activeKey: string) => void) | undefined;
  dataSource: any[] | undefined;
  loading: boolean;
  current: number;
  setCurrent: any;
  total: number;
}) {
  return (
    <>
      <Tabs
        defaultActiveKey="1"
        type="card"
        size="small"
        items={activityTabs}
        activeKey={activeKey}
        onChange={onChange}
        className="fluit-tabs"
      />
      <Table
        size="small"
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          current,
          pageSize: 30,
          onChange(page, pageSize) {
            setCurrent(page);
          },
          total,
          showSizeChanger: false,
        }}
      />
    </>
  );
}

export default ActivityTabs;
