import { useState } from "react";

import { toastAlert } from "../../utils/toastAlert";
import api from "../../config/axios";

type Props = {
  id: number | string;
  percentage: number;
  new_measurement?: boolean;
};

export const useUpdateMeasurement = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [refresh, setRefresh] = useState<Date>();

  const updateMeasurement = async ({ id, percentage, new_measurement }: Props) => {
    try {
      setIsUpdating(true);

      await api.put(`/v1/measurement-partials/${id}`, {
        percentage: percentage,
        new_measurement: new_measurement,
      });

      toastAlert({
        type: "success",
        message: "Measurement updated successfully.",
        title: "Measurement Updated",
      });
      setRefresh(new Date());
    } catch (error: any) {
      const msg = !!error.response.data.message ? error.response.data.message : "Confira sua conexão para tentar novamente.";
      toastAlert({
        type: "error",
        message: msg,
        title: "Erro",
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return { updateMeasurement, isUpdating, refresh };
};
