import { TableColumnsType, Tag } from "antd";
import { formatCurrency } from "../../../utils/formatCurrency";
import { TextCropToltip } from "../../../components/TextCropToltip";

import { formatPercentage } from "../../../utils/formatPercentage";

export const columns: TableColumnsType<any> = [
  {
    title: "Detalhes da Atividade",
    key: "activity_details",
    render: (text, record) => {
      return (
        <div>
          <div>
            <small>
              {record.execution_date
                ? new Date(record.execution_date).toLocaleDateString("pt-BR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "-"}
              <br />
            </small>
            <Tag color="purple">{record.activity?.project_information?.name || "-"}</Tag>
            <Tag color="purple">{record.activity?.percentual_group?.name || "-"}</Tag>
            <Tag color="purple">{record.activity?.percentual_group?.category || "-"}</Tag>
            <Tag color="purple">{record.activity?.name || "-"}</Tag>
          </div>
          <div style={{ marginTop: "8px" }}>
            <TextCropToltip text={record.activity?.iwp?.description || "-"} max_quantity={100} />
          </div>
        </div>
      );
    },
    width: 450,
  },
  {
    title: "Código",
    dataIndex: ["activity", "code"],
    key: "code",
    width: 250,
    render: (code, record) => (
      <a href={`/activity/${record.activity?.id}`} rel="noopener noreferrer">
        {code}
      </a>
    ),
  },
  {
    title: "Status",
    dataIndex: ["status"],
    key: "status",
    width: 150,
  },
  {
    title: "Justificativa",
    dataIndex: ["reason_for_refusal"],
    key: "reason_for_refusal",
    render: (text) => <div>{text || "-"}</div>,
    width: 150,
  },
  {
    title: "Valor Solicitado",
    dataIndex: ["activity", "cost"],
    key: "activity_cost",
    render: (cost) => <div>{cost ? formatCurrency(cost) : "-"}</div>,
    width: 150,
  },
  {
    title: "Porcentagem acumulada da medição",
    dataIndex: ["percentage"],
    key: "percentage",
    render: (percentage) => <div>{percentage !== null ? formatPercentage(percentage) : "-"}</div>,
    width: 200,
  },
  {
    title: "Porcentagem solicitada",
    dataIndex: ["initial_requested_percentage"],
    key: "initial_requested_percentage",
    render: (requestedPercentage) => <div>{requestedPercentage ? formatPercentage(requestedPercentage) : "-"}</div>,
    width: 200,
  },
  {
    title: "Porcentagem aprovada",
    dataIndex: "percentage",
    key: "approved_percentage",
    render: (percentage, record) => <div>{record.measure_refused_by === null || record.status_id === 4 ? formatPercentage(percentage) : "-"}</div>,
    width: 200,
  },
  {
    title: "Porcentagem Glosada",
    dataIndex: "old_percentage_value",
    key: "old_percentage_value",
    render: (oldPercentage) => <div>{oldPercentage !== undefined ? formatPercentage(oldPercentage) : "-"}</div>,
    width: 200,
  },
  {
    title: "Valor Glosado",
    dataIndex: "value_refused",
    key: "refused_percentage_cost",
    render: (valueRefused) => <div>{valueRefused !== null && valueRefused !== undefined ? formatCurrency(valueRefused) : "-"}</div>,
    width: 150,
  },
  {
    title: "Valor recusado",
    dataIndex: "rejected_cost",
    key: "rejected_cost",
    render: (rejectedCost) => <div style={{ color: "#a90202", fontWeight: "bold" }}>{rejectedCost ? formatCurrency(rejectedCost) : "-"}</div>,
    width: 150,
  },
  {
    title: "Valor aprovado",
    dataIndex: "value_aproved",
    key: "value_aproved",
    render: (valueApproved) => <div style={{ color: "#60a902", fontWeight: "bold" }}>{valueApproved ? formatCurrency(valueApproved) : "-"}</div>,
    width: 150,
  },
  {
    title: "Porcentagem para Pagamento",
    dataIndex: "percentage_for_payment",
    key: "percentage_for_payment",
    render: (percentageForPayment) => (
      <div style={{ color: "#60a902", fontWeight: "bold" }}>{percentageForPayment ? percentageForPayment + "%" : "-"}</div>
    ),
    width: 150,
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    width: 160,
  },
];

export default columns;
