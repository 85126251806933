import { Button } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../config/axios";
import { Activity } from "../../models/Activity";

type Props = {
  hired_id?: string;
  refresh?: Date;
  setDataTable: any;
  setDataTotal: any;
  currentPage: string | number;
  currentStatus: string | number;
  currentStatusType: string;
};

export const useFetchHiredActivitiesByStatus = ({
  hired_id,
  refresh,
  setDataTable,
  setDataTotal,
  currentPage,
  currentStatus,
  currentStatusType,
}: Props) => {
  const navigate = useNavigate();
  const [activities, setActivities] = useState<Activity[]>([]);
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const returnHiredActivities = useCallback(() => {
    setIsFetching(true);
    setDataTable([]);
    api
      .get(
        `/v1/hireds/${hired_id}/activities-by-status?current_status_type=${currentStatusType}&current_status=${currentStatus}&page=${currentPage}`
      )
      .then((response) => {
        const data = response.data.data;

        const table = data.map((obj: any) => ({
          ...obj,
          key: obj.id,
          actions: (
            <>
              <Button
                type="primary"
                onClick={() => navigate("/activity/" + obj.id)}
              >
                Abrir
              </Button>
            </>
          ),
        }));
        setActivities(table);
        setDataTable(table);
        setTotal(response.data.total);
        setDataTotal(response.data.total);
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hired_id,
    refresh,
    currentStatus,
    currentPage,
    currentStatus,
    currentStatusType,
  ]);

  useEffect(() => {
    returnHiredActivities();
  }, [returnHiredActivities]);

  return {
    activities,
    total,
    isFetching,
  };
};
