import { Button, DatePicker, Modal, Upload } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { useUploadActivityFile } from "../../../../hooks/files/useUploadActivityFile";
import { useState } from "react";
import dayjs from "dayjs";
import "./modalEvidence.scss";

type Props = {
  refreshList: any;
  activity_id?: string;
  isModalOpen: boolean;
  onClose: () => void;
};
export default function ModalEvidence({ refreshList, activity_id, isModalOpen, onClose }: Props) {
  const { handleUpload, isUploading } = useUploadActivityFile();
  const [date, setDate] = useState("");
  const disable = !!date;

  return (
    <>
      <Modal
        title="Anexe uma evidência"
        open={isModalOpen}
        onOk={onClose}
        onCancel={onClose}
        footer={[
          <Button key="back" onClick={onClose}>
            Cancelar
          </Button>,
        ]}
      >
        <div className="container-modal-evidence">
          <div className="container-date-radio">
            <span>Defina a data de execução da evidência:</span>
            <DatePicker allowClear format={"DD/MM/YYYY"} onChange={(e: any) => setDate(dayjs(e)?.format("YYYY-MM-DD"))} />
          </div>
          <Upload
            beforeUpload={(file) => {
              return false;
            }}
            showUploadList={false}
            className="upload-cloud-btn"
            onChange={(e) => {
              handleUpload({
                e,
                activity_id,
                setRefreshFileList: refreshList,
                execution_date: date,
              });
              onClose();
            }}
            disabled={isUploading}
            capture
          >
            <Button icon={<CloudUploadOutlined />} loading={isUploading} disabled={!disable}>
              {isUploading ? "Carregando arquivo" : "Selecionar arquivos..."}
            </Button>
          </Upload>
        </div>
      </Modal>
    </>
  );
}
