import { Button, Col, DatePicker, Popconfirm, Row, Spin, Table, Timeline, Typography, UploadFile } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchActivityHistory } from "../../hooks/activity/useFetchActivityHistory";
import { useFetchComments } from "../../hooks/activity/useFetchComments";
import { usePostComment } from "../../hooks/activity/usePostComment";
import { useFetchFilesByActivity } from "../../hooks/files/useFetchFilesByActivity";
import { Activity } from "../../models/Activity";
import { Measure } from "../../models/Measure";
import api from "../../services/Api";
import { toastAlert } from "../../utils/toastAlert";
import CommentCard from "../CommentCard";
import { MeasureFileListCard } from "../MeasureFileListCard";
import CollapseCard from "../Template/Card";
import "./measurePage.scss";
import MeasureModal from "./modal";

import { formatCurrency } from "../../utils/formatCurrency";
import { InformationCard } from "../ActivityView/cards";
import { formatPercentage } from "../../utils/formatPercentage";
import { partialColumns } from "../ActivityView/utils/partialColumns";
import { usePostDate } from "../../hooks/measure/usePostDate";

const { Title } = Typography;

function MeasureCreation({
  hasList,
  isPopup,
  activity,
  userRoles,
}: {
  hasList?: boolean;
  isPopup?: boolean;
  activity?: Activity;
  userRoles?: string[];
}) {
  const { measure_id } = useParams();

  const [refresh, setRefresh] = useState<Date>();
  const [loading, setLoading] = useState(true);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [comment, setComment] = useState<string>("");
  const [measure, setMeasure] = useState<Measure | undefined>();

  const [refreshCommentList, setRefreshCommentList] = useState(new Date());
  const [refreshFileList, setRefreshFileList] = useState(new Date());
  const [refreshHistory, setRefreshHistory] = useState(new Date());
  const [roles, setRoles] = useState<string[]>(userRoles || []);
  const navigate = useNavigate();

  const isRefused = measure && measure.status_id === 3;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const activity_id = measure?.activity_id;
  const { postDate, isPosting } = usePostDate();
  const [date, setDate] = useState("");
  const [defaultDate, setDefaultDate] = useState<any>(undefined);

  useEffect(() => {
    if (measure?.execution_date) setDefaultDate(dayjs(measure?.execution_date));
  }, [JSON.stringify(measure)]);

  const handlePostDate = () => {
    const formData = {
      date: date,
      id: measure?.id,
    };
    if (!isPosting) postDate({ formData });
    setRefreshHistory(new Date());
  };

  useEffect(() => {
    if (!userRoles) {
      api.get(`/v1/user/roles`).then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setRoles(data.roles);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (measure_id) {
      setLoading(true);
      api.get("/v1/measure/" + measure_id).then((response) => {
        setLoading(false);
        const data = response.data;

        setMeasure({
          ...data,
          key: data.id,
          content: data.description,
        });
        //  setDefaultDate(dayjs(data?.measurement_date));
      });
    }
  }, [measure_id, refresh]);

  const { history } = useFetchActivityHistory({
    activity_id: measure?.activity_id,
    refresh: refreshHistory,
  });

  const { isLoadingFiles } = useFetchFilesByActivity({
    activity_id: measure?.activity_id,
    setFileList,
    refreshFileList,
  });

  const { comments } = useFetchComments({
    activity_id: activity_id,
    refresh: refreshCommentList,
  });
  const { postComment, isFetching } = usePostComment();

  const handleSendComment = () => {
    const formData = {
      message: comment,
      activity_id: activity_id,
    };
    if (!isFetching) postComment({ formData });
  };

  useEffect(() => {
    setRefreshCommentList(new Date());
    setComment("");
  }, [isFetching]);

  const handleAprove = () => {
    api
      .patch(`/v1/measure/${measure_id}/aprove`, {
        measure_id,
      })
      .then((response) => {
        toastAlert({
          type: "success",
          message: `Já é possível encontrá-la na aba de medições.`,
          title: "Medição aprovada",
        });
        setRefresh(new Date());
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `Verifique sua conexão e tente novamente.`,
          title: "Não foi possível aprovar a medição",
        });
      });
  };

  const userCanAprove = () => {
    if (roles.length === 0) return false;
    else if (["Administrador", "Owner", "Subowner", "Subowner_cost"].includes(roles[0])) return true;
    else return false;
  };

  return (
    <Row gutter={16} className="page-row">
      <Col xs={24} lg={12}>
        <InformationCard
          status_id={1}
          showContractCard={false}
          criteria={""}
          isPopup={false}
          activity={measure?.activity}
          isMeasureScreen={true}
          setRefreshActivity={setRefresh}
        />

        <MeasureFileListCard
          fileList={fileList}
          canCollapse
          isLoading={isLoadingFiles}
          refreshList={() => {
            setRefreshFileList(new Date());
            setRefreshHistory(new Date());
          }}
          roles={roles}
          showButton={true}
          hideActions={false}
        />

        <CollapseCard title="Histórico da Atividade" style={{ marginBottom: isPopup ? 0 : 20 }}>
          <Row
            style={{
              display: "flex",
              gap: 8,
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Timeline
              mode="left"
              reverse
              items={history?.map((item) => ({
                label: `${new Date(item.created_at).toLocaleDateString("pt-BR")} ${new Date(item.created_at).toLocaleTimeString("pt-BR", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}`,
                children: item.message,
              }))}
            />
          </Row>
        </CollapseCard>
      </Col>

      <Col xs={24} lg={12} className="mb-2">
        {userCanAprove() ? (
          <CollapseCard
            title={`Ações`}
            style={{
              marginBottom: isPopup ? 0 : 20,
              width: "100%",
            }}
          >
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col span={24}>
                <Col>
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        padding: 16,
                      }}
                    >
                      <Spin />
                    </div>
                  ) : (
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: 8,
                          justifyContent: "space-between",
                        }}
                      >
                        {userCanAprove() ? (
                          <div
                            style={{
                              gap: 8,
                              display: "flex",
                              justifyContent: "flex-end",
                              flexWrap: "wrap",
                              width: "100%",
                            }}
                          >
                            {isRefused ? (
                              <span>Medição foi reprovada, não há ações disponíveis</span>
                            ) : measure && !measure?.measure_approved_by ? (
                              <>
                                <Button type="primary" onClick={() => setIsModalOpen(true)} className="refuse-measure">
                                  Reprovar item de medição
                                </Button>
                                <Popconfirm
                                  title="Aprovação de medição"
                                  description="Tem certeza que deseja aprovar essa medição"
                                  onConfirm={handleAprove}
                                  onCancel={() => {}}
                                  okText="Sim"
                                  cancelText="Não"
                                >
                                  <Button type="primary" className="aprove-measure">
                                    Aprovar item de medição
                                  </Button>
                                </Popconfirm>
                              </>
                            ) : (
                              <div>Medição foi aprovada</div>
                            )}
                          </div>
                        ) : (
                          <div>Sem ações permitidas</div>
                        )}
                      </div>
                    </Row>
                  )}
                </Col>
              </Col>
            </Row>
          </CollapseCard>
        ) : null}

        <div className="container-cards-measure">
          <div className="card-cost">
            <CollapseCard title="Valor da atividade" style={{ marginBottom: 20 }}>
              <Title level={4} className="text-center measure-contract-value">
                {!!measure ? formatCurrency(measure.cost ? +measure.cost : 0) : <Spin />}
              </Title>
            </CollapseCard>
          </div>
          <div className="card-cost">
            <CollapseCard title="Valor do contrato" style={{ marginBottom: 20 }} className="card-cost">
              <Title level={4} className="text-center measure-contract-value">
                {!!measure ? (
                  measure?.contract_value ? (
                    `R$ ${
                      measure?.contract_value
                        ? Number(measure?.contract_value)?.toLocaleString("pt-BR", {
                            currency: "BRL",
                            minimumFractionDigits: 2,
                          })
                        : ""
                    }`
                  ) : (
                    "-"
                  )
                ) : (
                  <Spin />
                )}
              </Title>
            </CollapseCard>
          </div>
        </div>
        <div className="container-cards-measure">
          {!!measure && (
            <div className="card-cost">
              <CollapseCard title="Porcentagem da medição" style={{ marginBottom: 20 }}>
                <Title level={4} className="text-center measure-contract-value">
                  {!!measure ? formatPercentage(measure?.partial ? measure.partial.percentage : "100") : <Spin />}
                </Title>
              </CollapseCard>
            </div>
          )}
          {!!measure && (
            <div className="card-cost">
              <CollapseCard title="Total de avanço" style={{ marginBottom: 20 }}>
                <Title level={4} className="text-center measure-contract-value">
                  {!!measure ? formatPercentage(String(measure?.difference)) : <Spin />}
                </Title>
              </CollapseCard>
            </div>
          )}
          {!!measure && (
            <div className="card-cost">
              <CollapseCard title="Valor a pagar" style={{ marginBottom: 20 }}>
                <Title level={4} className="text-center measure-contract-value">
                  {!!measure ? formatCurrency(measure.total_pay) : <Spin />}
                </Title>
              </CollapseCard>
            </div>
          )}
        </div>
        <CollapseCard
          title={`Data de execução`}
          style={{
            marginBottom: isPopup ? 0 : 20,
            width: "100%",
          }}
        >
          {!!measure && !isLoadingFiles && (
            <div style={{ display: "flex", gap: "8px", justifyContent: "flex-end" }}>
              <DatePicker
                allowClear
                disabled={!!activity?.measure?.measure_approved_by}
                format={"DD/MM/YYYY"}
                onChange={(e: any) => setDate(dayjs(e)?.format("YYYY-MM-DD"))}
                defaultValue={defaultDate}
              />
              <Button
                onClick={() =>
                  date === ""
                    ? toastAlert({
                        type: "warning",
                        message: `Selecione uma data válida`,
                        title: "Espere!",
                      })
                    : handlePostDate()
                }
                className="comment-submit"
                disabled={isPosting}
                loading={isPosting}
                type="primary"
              >
                Atribuir data
              </Button>
            </div>
          )}
        </CollapseCard>
        {measure?.is_partial && measure?.partials.length > 0 && (
          <CollapseCard
            title={`Medições vinculadas à atividade`}
            style={{
              marginBottom: isPopup ? 0 : 20,
              width: "100%",
            }}
          >
            <div className="container-values">
              <span>Valor a pagar: {formatCurrency(measure?.total_pay)}</span>
              <span>Valor medido: {formatCurrency(measure?.total_payable)}</span>
            </div>
            <Table
              size="small"
              scroll={{ x: 1000 }}
              dataSource={measure?.partials?.map((obj: any) => ({
                ...obj,
                actions: (
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button type="primary" onClick={() => navigate(`/measure/${obj.measure_id}`)}>
                      Ver medição
                    </Button>
                  </div>
                ),
              }))}
              columns={partialColumns}
            />
          </CollapseCard>
        )}

        <CommentCard
          commentList={comments}
          inputComment={comment}
          setInputComment={setComment}
          handleSendComment={handleSendComment}
          uploading={isFetching}
        />

        {isModalOpen ? (
          <MeasureModal
            isModalOpen={isModalOpen}
            handleClose={() => {
              setIsModalOpen(false);
              setRefresh(new Date());
            }}
          />
        ) : null}
      </Col>
    </Row>
  );
}

export default MeasureCreation;
