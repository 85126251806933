import { Badge } from "antd";

export const statusTabs = [
  {
    label: <Badge status="processing" text="Atividades" />,
    key: "activities",
    children: <></>,
  },
  {
    label: <Badge status="processing" text="Validação de evidência" />,
    key: "evidence",
    children: <></>,
  },
  {
    label: <Badge status="processing" text="Medição" />,
    key: "measurement",
    children: <></>,
  },
];

export const activityTabs = [
  {
    label: <Badge status="default" text="Não iniciadas" />,
    key: "1",
    children: <></>,
  },
  {
    label: <Badge status="warning" text="Pendentes" />,
    key: "2",
    children: <></>,
  },
  {
    label: <Badge status="success" text="Aprovadas" />,
    key: "3",
    children: <></>,
  },
  {
    label: <Badge status="error" text="Recusadas" />,
    key: "4",
    children: <></>,
  },
];

export const measureTabs = [
  {
    label: <Badge status="default" text="Pendentes" />,
    key: "1",
    children: <></>,
  },
  {
    label: <Badge status="success" text="Aprovadas" />,
    key: "4",
    children: <></>,
  },
  {
    label: <Badge status="error" text="Recusadas" />,
    key: "3",
    children: <></>,
  },
];

export const evidenceTabs = [
  {
    label: <Badge status="default" text="Pendentes" />,
    key: "1",
    children: <></>,
  },
  {
    label: <Badge status="success" text="Aprovadas" />,
    key: "4",
    children: <></>,
  },
  {
    label: <Badge status="error" text="Recusadas" />,
    key: "3",
    children: <></>,
  },
];
