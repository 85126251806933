import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import formatDate from "../../../utils/formatDate";
import { TextCropToltip } from "../../TextCropToltip";

export const columns = [
  {
    title: "Nome",
    dataIndex: "show_name",
    render: (name: any) => (
      <>
        <TextCropToltip text={name} max_quantity={20} />
      </>
    ),
  },

  {
    title: "Data de criação",
    dataIndex: "created_at",
    render: (name: any) => <>{new Date(name).toLocaleDateString("pt-BR")}</>,
  },
  {
    title: "Data de execução",
    dataIndex: "activity_date",
    render: (name: any) => <>{name ? formatDate(name) : "-"}</>,
  },
  {
    title: "Etapa",
    dataIndex: "etapa",
  },
  {
    title: "Step de medição",
    dataIndex: "step",
    key: "step",
    render: (step: any) => <TextCropToltip text={step} max_quantity={35} />,
  },
  {
    title: "Qualidade",
    dataIndex: "quality_date",
    render: (name: any, record: any) => (
      <div className={"file-table-list-date " + (!!record.uploadData?.quality_approved_by ? "positive-text-color" : "negative-text-color")}>
        {!!name ? (
          <>
            {!!record.uploadData?.quality_approved_by ? <FaCheck size={18} /> : <IoClose size={20} />}
            {new Date(name + "T00:00:00").toLocaleDateString("pt-BR")}
          </>
        ) : (
          ""
        )}
      </div>
    ),
  },
  {
    title: "Controle",
    dataIndex: "control_date",
    render: (name: any, record: any) => (
      <div className={"file-table-list-date " + (!!record.uploadData?.control_approved_by ? "positive-text-color" : "negative-text-color")}>
        {!!name ? (
          <>
            {!!record.uploadData?.control_approved_by ? <FaCheck size={18} /> : <IoClose size={20} />}
            {new Date(name + "T00:00:00").toLocaleDateString("pt-BR")}
          </>
        ) : (
          ""
        )}
      </div>
    ),
  },
  { dataIndex: "actions", width: 90 },
];
